import { createContext, useContext } from "react";
import { AuthContext } from ".";
import { toast } from 'react-toastify';
import { conection } from '../utils/index'
import moment from 'moment';

export const AuthContextApi = createContext();


const AuthProvider = ({ children }) => {

    const { states, setters } = useContext(AuthContext);
    const axiosAuth = conection.auth();
    const axiosNoAuth = conection.noAuth();


    const checkConfigCliente = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosNoAuth.get(`/website/checkCliente/cliente/${token}`)
            setters.setErrorAcessoCliente(false)
            if (response.status === 200) {
                localStorage.setItem('clienteConfig', JSON.stringify(response.data.data))
                setters.setClienteConfig(response.data.data)
                setters.setClientePossuiConfig(true)
            } else {
                const haveLocalStorage = localStorage.getItem('clienteConfig')
                if (haveLocalStorage) {
                    localStorage.removeItem('clienteConfig')
                }
                setters.setClienteConfig({})
                setters.setClientePossuiConfig(false)
                setters.setErrorAcessoCliente(true)
                setters.setClienteConfig({})
                setters.setClientePossuiConfig(false)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente('Painel de cliente inativo, tente novamente em alguns instantes')
            }
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response.status !== 402) {
                const haveLocalStorage = localStorage.getItem('clienteConfig')
                if (haveLocalStorage) {
                    localStorage.removeItem('clienteConfig')
                }
                setters.setClienteConfig({})
                setters.setClientePossuiConfig(false)
            } else {
                localStorage.setItem('clienteConfig', JSON.stringify(err.response.data.data))
                setters.setClienteConfig(err.response.data.data)
                setters.setClientePossuiConfig(true)
            }
            setters.setIsLoading(false)
            setters.setErrorAcessoCliente(true)
            setters.setMsgErrorAcessoCliente(err.response.data.msg)
            return false;
        }
    }

    const login = async (data, token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosNoAuth.post('/clienteSecundario/login', { ...data, id: token })
            console.log(response.data)
            localStorage.setItem('token', response.data.data.token)
            localStorage.setItem('nome', response.data.data.nome)
            localStorage.setItem('email', response.data.data.email)
            localStorage.setItem('conversa', response.data.data.conversa)
            await checkConfigCliente(token)
            setters.setSigned(true)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return { status: true, complete: true }
        } catch (err) {
            if (err.response.data.danger) {
                setters.setClienteConfig({})
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const checkLogin = async () => {
        try {
            const header = {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            await axiosAuth.get('/user/check', header)
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }


    const logout = async () => {
        setters.setIsLoading(true)
        try {
            localStorage.removeItem('token')
            localStorage.removeItem('permisssion')
            localStorage.removeItem('usuario')
            localStorage.removeItem('nome')
            localStorage.removeItem('iamPrestador')
            localStorage.removeItem('empresa')
            localStorage.removeItem('haveConfig')
            localStorage.removeItem('havePrestador')
            localStorage.removeItem('haveCategoria')
            localStorage.removeItem('haveContabilidadeConfig')
            localStorage.removeItem('haveServicos')
            localStorage.removeItem('perfil')
            await axiosAuth.get('/user/logOut')
            setters.setSigned(false)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            return false;
        }
    }



    //#region Serviço -> a partir dessa estao em uso

    const getExecucaoServicoDataSistemaPaciente = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/dadosSistema/execucaoServico/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaExecucoesServico(response.data.data)
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('nome')
                localStorage.removeItem('email')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }

    const getExecucaoServicoPaciente = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/execucaoServico/cliente/getWithLimit/clienteSecundario/${states.limiteExecucoesServico}&${states.offsetExecucoesServico}`)
            setters.setIsLoading(false)
            setters.setDataExecucoesServico(response.data.data)
            if (states.dataExecucoesServico.length === 0) toast.success('Atendimentos de serviço carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('nome')
                localStorage.removeItem('email')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaExecucoesServico(true)
                if (states.offsetExecucoesServico > 0) {
                    setters.setOffsetExecucoesServico(states.offsetExecucoesServico - states.limiteExecucoesServico)
                }
                if (states.offsetExecucoesServico < 0) {
                    setters.setOffsetExecucoesServico(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }


    //#endregion


    //#region Agenda 

    const getMinhaAgendaMonth = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/agenda/cliente/clienteSecundario/getMonth`, { data: data })
            setters.setIsLoading(false)
            setters.setDataAgenda(response.data.data)
            if (states.dataAgenda.length === 0) toast.success('Agenda carregada com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('email')
                localStorage.removeItem('nome')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getMeuDayCompromissos = async (data) => {
        setters.setIsLoading(true)
        try {
            const body = {
                data: data,
                limit: states.limiteTarefas,
                offset: 0
            }
            const response = await axiosAuth.post(`/agenda/cliente/clienteSecundario/getDay`, body)
            setters.setIsLoading(false)
            if (response.data.noData) {
                setters.setLimiteBuscaTarefas(true)
                if (states.offsetTarefas > 0) {
                    setters.setOffsetTarefas(states.offsetTarefas - states.limiteTarefas)
                }
                if (states.offsetTarefas < 0) {
                    setters.setOffsetTarefas(0)
                }
                setters.setDataTarefas([])
                toast.info('Nenhum compromisso encontrado')
                return;
            }
            setters.setDataTarefas(response.data.data)
            toast.success('Compromissos carragados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getMeuDayCompromissosMore = async (data) => {
        setters.setIsLoading(true)
        try {
            const body = {
                data: data,
                limit: states.limiteTarefas,
                offset: states.offsetTarefas
            }
            const response = await axiosAuth.post(`/agenda/cliente/clienteSecundario/getDay`, body)
            setters.setIsLoading(false)
            if (response.data.noData) {
                setters.setLimiteBuscaTarefas(true)
                if (states.offsetTarefas > 0) {
                    setters.setOffsetTarefas(states.offsetTarefas - states.limiteTarefas)
                }
                if (states.offsetTarefas < 0) {
                    setters.setOffsetTarefas(0)
                }
                toast.info('Nenhum compromisso encontrado')
                return;
            }
            if (states.dataTarefas.length === 0) setters.setDataTarefas(response.data.data)
            else {
                const isSameDataInStates = response.data.data.filter((item) => {
                    return states.dataTarefas.find((item2) => moment(item2.data).isSame(item.data)) !== undefined
                })
                if (isSameDataInStates.length > 0) setters.setDataTarefas([...states.dataTarefas, ...isSameDataInStates])
                else setters.setDataTarefas(response.data.data)
            }
            toast.success('Compromissos carragados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //endregion


    //#region conversa


    const getPacientConversas = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comunicacaoCliente/clienteSecundario/getWithLimit/${states.limiteConversasPaciente}&${states.offsetConversasPaciente}`)
            setters.setIsLoading(false)
            if (states.dataConversasPaciente.length === 0) {
                setters.setDataConversasPaciente(response.data.data)
            } else {
                const dataOutArr = response.data.data.filter((item) => {
                    return states.dataConversasPaciente.find((item2) => item2.id === item.id) === undefined
                })
                setters.setDataConversasPaciente([...states.dataConversasPaciente, ...dataOutArr])

            }
            if (states.dataConversasPaciente.length === 0) toast.success('Conversas carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('email')
                localStorage.removeItem('nome')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaConversasPaciente(true)
                if (states.offsetConversasPaciente > 0) {
                    setters.setOffsetConversasPaciente(states.offsetConversasPaciente - states.limiteConversasPaciente)
                }
                if (states.offsetConversasPaciente < 0) {
                    setters.setOffsetConversasPaciente(0)
                }
                toast.error(err.response.data.msg)
            }
        }

    }

    const getPacientConversa = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comunicacaoCliente/clienteSecundario/${id}`)
            setters.setIsLoading(false)
            setters.setConversaPaciente(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('email')
                localStorage.removeItem('nome')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            toast.error(err.response.data.msg)
        }

    }


    const getPacientConversaMensagens = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comunicacaoConversa/clienteSecundario/getWithLimit/${id}/${states.limiteConversaPaciente}&${states.offsetConversaPaciente}`)
            setters.setIsLoading(false)
            if (states.dataConversaPaciente.length === 0) setters.setDataConversaPaciente(response.data.data)
            else {
                const dataOutArr = response.data.data.filter((item) => {
                    return states.dataConversaPaciente.find((item2) => item2.id === item.id) === undefined
                })
                setters.setDataConversaPaciente([...states.dataConversaPaciente, ...dataOutArr])
            }
            toast.success('Mensagens carregados com sucesso')
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                setters.setLimiteBuscaConversaPaciente(true)
                if (states.offsetConversaPaciente > 0) {
                    setters.setOffsetConversaPaciente(states.offsetConversaPaciente - states.limiteConversaPaciente)
                }
                if (states.offsetConversaPaciente < 0) {
                    setters.setOffsetConversaPaciente(0)
                }
            }
        }

    }


    const postMensagemConversa = async (data, id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/comunicacaoConversa/clienteSecundario`, data)
            setters.setIsLoading(false)
            toast.success('Mensagem enviada com sucesso')
            await getPacientConversaMensagens(id)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region perfil 

    const getMeusDados = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/meuPerfil/clienteSecundario`)
            setters.setIsLoading(false)
            setters.setMeuPerfil(response.data.data)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('nome')
                localStorage.removeItem('email')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    const updateMyPerfil = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.put(`/meuPerfil/clienteSecundario`, data)
            setters.setIsLoading(false)
            setters.setMeuPerfil(response.data.data)
            toast.success('Perfil atualizado com sucesso')
            await getMeusDados()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }


    const profileChangeSenha = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/clienteSecundario/changePassword`, data)
            const saveSenha = localStorage.getItem('userPassword')
            if (saveSenha) {
                localStorage.setItem('userPassword', data.novaSenha)
            }
            setters.setIsLoading(false)
            toast.success('Senha alterado com sucesso.')
            return true;
        } catch (err) {
            console.log(err)
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const profileChangeEmail = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/clienteSecundario/changeEmail`, data)
            setters.setIsLoading(false)
            const saveEmail = localStorage.getItem('userEmail')
            if (saveEmail) {
                localStorage.setItem('userEmail', data.email)
            }
            toast.success('Email alterado com sucesso.')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    const forgotPass = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosNoAuth.post('/clienteSecundario/forgotPassword', data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response.data.danger) {
                setters.setClienteConfig({})
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            setters.setIsLoading(false)
            return false;
        }
    }

    const forgotChangePass = async (token, data) => {
        setters.setIsLoading(true)
        try {
            await axiosNoAuth.put(`/clienteSecundario/forgotChangePass/${token}`, data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            console.log(err)
            if (err.response.data.danger) {
                setters.setClienteConfig({})
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            setters.setIsLoading(false)
            return false;
        }
    }


    //#endregion


    return (
        <AuthContextApi.Provider
            value={{
                profileChangeSenha,
                profileChangeEmail,
                updateMyPerfil,
                getExecucaoServicoDataSistemaPaciente,
                getExecucaoServicoPaciente,
                getMeusDados,
                getMinhaAgendaMonth,
                getMeuDayCompromissos,
                getMeuDayCompromissosMore,
                postMensagemConversa,
                getPacientConversa,
                getPacientConversaMensagens,
                getPacientConversas,
                checkConfigCliente,
                login,
                checkLogin,
                forgotPass,
                forgotChangePass,
                logout

            }}>
            {children}
        </AuthContextApi.Provider>
    )
}

export default AuthProvider;
