
const jsonConfig = {
    urlReact: "https://paciente.sotertec.com.br",
    // urlAPI: 'http://localhost:7846/psicologo',
    urlAPI: 'https://psicologo.sotertec.com.br/psicologo',
    APIKEY: `Bearer ${process.env.REACT_APP_APIKEY}`,
    limitDefault: 15,
    limitDefaultMsg: 8,
    adminPermission: "CLIENTE",
    entidadeRegistro: "CRP",
    nomeClienteSecundario: "Pacientes",
}


export { jsonConfig }