import { Link, useParams } from 'react-router-dom'
import { AuthContext } from "../../Context"
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useState, useContext, useEffect } from 'react'
import { jsonConfig } from '../../Config'
import {
    HomeIcon, ChatBubbleBottomCenterIcon, UserIcon, UserPlusIcon,
    CalendarDaysIcon
} from '@heroicons/react/24/solid'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')//
}

const Sidebar = () => {
    const { token } = useParams()
    const { states, setters } = useContext(AuthContext)
    const [navigation, setNavigation] = useState([]);
    const [secondaryNavigation, setSecondaryNavigation] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true)
    const [config, setConfig] = useState({})



    let navigationData = null;

    if (localStorage.getItem('conversa') === 'true') {
        navigationData = [
            { name: 'DashBoard', href: `/dashboard/${token}`, icon: HomeIcon, current: false },
            { name: 'Minha Agenda', href: `/minhaAgenda/${token}`, icon: CalendarDaysIcon, current: false },
            { name: 'Meus Atendimentos', href: `/servicos/${token}`, icon: UserPlusIcon, current: false },
            { name: 'Conversas', href: `/conversas/${token}`, icon: ChatBubbleBottomCenterIcon, current: false }
        ]
    } else {
        navigationData = [
            { name: 'DashBoard', href: `/dashboard/${token}`, icon: HomeIcon, current: false },
            { name: 'Minha Agenda', href: `/minhaAgenda/${token}`, icon: CalendarDaysIcon, current: false },
            { name: 'Meus Atendimentos', href: `/servicos/${token}`, icon: UserPlusIcon, current: false }
        ]

    }




    const currentItem = (clicked) => {
        const updatedNavigation = navigation.map((item) => {
            const current = item.href.split('/');
            if (current[1] === clicked.href.replace('/', '')) {
                return { ...item, current: true };
            } else {
                return { ...item, current: false };
            }
        });
        setNavigation(updatedNavigation)
        const updateSecondary = secondaryNavigation.map((item) => {
            return { ...item, current: false };
        });
        setSecondaryNavigation(updateSecondary)

    }

    let secondaryNavigationData = [
        { name: 'Perfil', href: `/perfil/${token}`, icon: UserIcon, current: false },
    ]

    const currentItemSecondary = (clicked) => {
        const updatedNavigation = secondaryNavigation.map((item) => {
            if (item.href === clicked.href) {
                return { ...item, current: true };
            } else {
                return { ...item, current: false };
            }
        });
        setSecondaryNavigation(updatedNavigation)
        const updatePrimary = navigation.map((item) => {
            return { ...item, current: false };
        });
        setNavigation(updatePrimary)
    }

    const checkPath = () => {
        const splitBar = window.location.pathname.split('/')
        if (splitBar[1] !== 'perfil') {
            currentItem({ href: `/${splitBar[1]}` })
            const updateSecondary = secondaryNavigation.map((item) => {
                return { ...item, current: false };
            }
            );
            setSecondaryNavigation(updateSecondary)
        } else {
            const updatePrimary = navigation.map((item) => {
                return { ...item, current: false };
            });
            setNavigation(updatePrimary)
            currentItemSecondary({ href: `/${splitBar[1]}` })
        }
    }

    const closeSideBarMobile = () => {
        setters.setSideBar(false)
    }

    useEffect(() => {
        setFirstLoad(true)
        setNavigation(navigationData)
        setSecondaryNavigation(secondaryNavigationData)


        return () => {
            setNavigation([])
            setSecondaryNavigation([])
            setFirstLoad(true)
        }
    }, [])

    useEffect(() => {
        if (firstLoad && navigation.length > 0 && secondaryNavigation.length > 0) {
            checkPath()
            setFirstLoad(false)
        }
    }, [navigation, secondaryNavigation])

    useEffect(() => {
        if (navigation.length > 0 && secondaryNavigation.length > 0) {
            checkPath()
        }
    }, [window.location.pathname])


    useEffect(() => {
        if (Object.keys(states.clienteConfig).length > 0) {
            setConfig(states.clienteConfig)
        } else {
            const configStorage = JSON.parse(localStorage.getItem('clienteConfig'))
            setters.setClienteConfig(configStorage)
            setConfig(configStorage)
        }
    }, [states.clienteConfig])


    return (
        <>
            {/* ABERTO */}
            {
                states.sideBar === false &&
                <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-grow flex-col  bg-blue-500 pt-5 pb-4 overflow-y-auto">
                        <div className="flex items-center justify-center flex-shrink-0 items-center px-4">
                            {
                                Object.keys(config).length === 0 ? (<>
                                    <img
                                        className="h-[5em] w-auto"
                                        src={`${jsonConfig.urlReact}/logo.png`}
                                        alt="Soter Tec Logo"
                                    />
                                </>) : (<>
                                    <img
                                        className="h-[5em] w-auto"
                                        src={`data:image/png;base64,${config.logo}`}
                                        alt={`Logo ${config.cliente_nome}`}
                                    />
                                </>)
                            }
                        </div>

                        <nav className="flex flex-1 flex-col p-5 mt-5">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul role="list" className="-mx-2 space-y-2">
                                        {navigation.map((item) => (
                                            <li key={item.name}>
                                                {
                                                    states.disableNavigation === true ? (
                                                        <span
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={() => currentItem(item)}
                                                            className={classNames(
                                                                item.current
                                                                    ? 'bg-blue-600 text-white'
                                                                    : 'text-blue-200 hover:text-white hover:bg-blue-600',
                                                                'group flex gap-x-3 rounded-md p-2 text-md leading-6 font-semibold'
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    item.current ? 'text-white' : 'text-blue-200 group-hover:text-white',
                                                                    'h-6 w-6 shrink-0'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </span>

                                                    ) : (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={() => currentItem(item)}
                                                            className={classNames(
                                                                item.current
                                                                    ? 'bg-blue-600 text-white'
                                                                    : 'text-blue-200 hover:text-white hover:bg-blue-600',
                                                                'group flex gap-x-3 rounded-md p-2 text-md leading-6 font-semibold'
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    item.current ? 'text-white' : 'text-blue-200 group-hover:text-white',
                                                                    'h-6 w-6 shrink-0'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </Link>
                                                    )
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="mt-auto">
                                    <div className="text-xs font-semibold leading-6 text-blue-200 mt-5 border-t" />
                                    <ul role="list" className="-mx-2 mt-2 space-y-1 mt-5">
                                        {secondaryNavigation.map((item) => (
                                            <li key={item.name}>
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    onClick={() => currentItemSecondary(item)}
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-blue-600 text-white'
                                                            : 'text-blue-200 hover:text-white hover:bg-blue-600',
                                                        'group flex gap-x-3 rounded-md p-2 text-md leading-6 font-semibold'
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current ? 'text-white' : 'text-blue-200 group-hover:text-white',
                                                            'h-6 w-6 shrink-0'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            }



            <Transition.Root show={states.sideBar} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setters.setSideBar}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-500 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setters.setSideBar(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center justify-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src={`${jsonConfig.urlReact}/logo.png`}
                                        alt="Sinprev logo"
                                    />
                                </div>
                                <nav
                                    className="mt-5 h-full flex-shrink-0 overflow-y-auto"
                                    aria-label="Sidebar"
                                >
                                    <div className="space-y-1 px-2">
                                        {navigation.map((item) => (
                                            <Link
                                                onClick={() => closeSideBarMobile()}
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-blue-600 text-white'
                                                        : 'text-blue-200 hover:text-white hover:bg-blue-600',
                                                    'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>

                                    <div className="mt-6 pt-6 border-t">
                                        <div className="space-y-1 px-2 ">
                                            {secondaryNavigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-blue-200 hover:text-white hover:bg-blue-600"
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    <item.icon className="mr-4 h-6 w-6 text-cyan-200" aria-hidden="true" />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </nav>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}


export default Sidebar